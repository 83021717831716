<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tipos de Vehículo</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Tipos de Vehículo</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_tipo_vehiculo_export"
                        v-if="$store.getters.can('admin.tiposVehiculo.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        v-if="$store.getters.can('admin.tiposVehiculo.create')"
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        Tipo Vehículo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th style="width: 15%">
                        Cantidad de pasajeros
                      </th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="tipo_vehiculo in tipos_vehiculos.data"
                      :key="tipo_vehiculo.id"
                    >
                      <td>{{ tipo_vehiculo.nombre }}</td>
                      <td>{{ tipo_vehiculo.cantidad_pasajeros }}</td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="
                              $store.getters.can('admin.tiposVehiculo.show')
                            "
                            @click="edit(tipo_vehiculo)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            @click="destroy(tipo_vehiculo.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="tipos_vehiculos.total">
                  <p>
                    Mostrando del <b>{{ tipos_vehiculos.from }}</b> al
                    <b>{{ tipos_vehiculos.to }}</b> de un total:
                    <b>{{ tipos_vehiculos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="tipos_vehiculos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <TipoVehiculoExport ref="TipoVehiculoExport" />
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength, between } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import TipoVehiculoExport from "./TipoVehiculoExport";

export default {
  name: "TipoVehiculoIndex",
  components: {
    pagination,
    TipoVehiculoExport,
  },
  data() {
    return {
      filtros: {
        nombre: null,
        cantidad_pasajeros: null,
      },
      tipos_vehiculos: {},
      objTipoVehiculo: {},
      modal: {
        title: "",
        accion: "",
      },
      form: {
        nombre: true,
        cantidad_pasajeros: null,
      },
    };
  },
  validations: {
    objTipoVehiculo: {
      nombre: {
        required,
      },
      cantidad_pasajeros: {
        required,
      },
    },
  },
  methods: {
    getIndex(page = 1) {
      axios
        .get("/api/admin/tiposVehiculos?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.tipos_vehiculos = response.data;
        });
    },

    save() {
      if (!this.$v.objTipoVehiculo.$invalid) {
        axios
          .post("/api/admin/tiposVehiculos", this.objTipoVehiculo)
          .then((response) => {
            document.getElementById("close-modal").click();
            this.objTipoVehiculo = {};
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vualeva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    show(idTipoVehiculo) {
      axios
        .get("/api/admin/tiposVehiculos/show/" + idTipoVehiculo)
        .then((response) => {
          this.objTipoVehiculo = response.data;
        });
    },

    destroy(idTipoVehiculo) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/admin/tiposVehiculos/" + idTipoVehiculo)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    create() {
      return this.$router.push({
        name: "/Admin/TipoVehiculoForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    async edit(tipoVehi) {
      return await this.$router.push({
        name: "/Admin/TipoVehiculoForm",
        params: { accion: "Editar", data_edit: tipoVehi, id: tipoVehi.id },
      });
    },
    generarListadoExcel() {
      this.$parent.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/tiposVehiculos/export",
        data: this.form,
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },
  },
  mounted() {
    this.getIndex();
  },
};
</script>